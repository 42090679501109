export {json as paper3}
const json=`
<p style=";text-align: center; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 28px; font-weight: bold; font-family: 华文中宋;">渥康</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">渥康（拉丁文：<span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span>）是慧智国际有限公司旗下品牌，新西兰乳品品牌。品牌旗下拥有众多生产工厂和牧场奶源，包括全球稀有的A2有机奶源和A2奶源，让渥康拥有研发乳品的硬实力。&nbsp;</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">渥康秉持「创造未来乳品」的态度。2018年，渥康被新西兰《市场杂志》评为「新西兰第一品牌高端常温奶」。2019年，渥康荣获「新西兰国家食品奖」冠军。凭借产品研发，渥康相继成为2017年「世界饮品创新奖」冠军和2021年「世界乳品创新奖」冠军。已在全球范围内20多个国家的高端乳品领域都有良好的表现。</span></p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">的品牌创始人是</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">Mr. Ivan Clay</span><span style="font-size: 12px; font-family: 华文中宋;">艾尔文</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">&middot;</span><span style="font-size: 12px; font-family: 华文中宋;">格雷先生。</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">一词源自拉丁文，其中</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">DOM</span><span style="font-size: 12px; font-family: 华文中宋;">是「最好的、最伟大的」的缩写，这是艾尔文先生用拉丁文字为品牌注入的品牌使命；品牌中文取名渥康，渥取意「丰盛」，康取意「富足」，渥康寓意「盛世繁华」。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">&nbsp;</span></p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">1989</span><span style="font-size: 12px; font-family: 华文中宋;">年，艾尔文为玻璃瓶装的鲜牛奶贴上以拉丁文命名的</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">品牌标签，在纳皮尔小镇的超市、便利店和咖啡厅售卖。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">1997</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">品牌推出了</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">PET</span><span style="font-size: 12px; font-family: 华文中宋;">瓶装的纯牛奶。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">1999</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">纯牛奶销往新西兰北岛的其它城市。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2005</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">纯牛奶销往新西兰南岛的部分城市。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2012</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">品牌完成升级，重新定义品牌价值，指向高端消费人群。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2013</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">品牌取中文名，渥康。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2014</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">品牌推出了无乳糖奶。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2016</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">品牌推出了无化学添加剂的黑巧克力奶。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2017</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">9</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">黑巧奶在德国慕尼黑独揽世界饮品创新奖的最佳儿童饮品奖金奖。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2017</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">首次以中文名，渥康的身份，经一般贸易登陆中国市场。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌受邀成立渥康京东自营旗舰店。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌受邀成立渥康京东旗舰店。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康无乳糖奶参与世界多国专业赛事并荣获多项提名，如在新西兰获新西兰国家食品奖冠军提名；在德国获世界饮品创新奖的最佳乳饮品奖和最佳包装设计奖双项冠军提名；在日本获国际饮品奖的最佳品牌奖和最佳无添加饮品奖双项冠军提名；在新加坡获亚洲食品创新奖的最佳无添加食品奖和最佳包装设计奖双项冠军提名等。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康黑巧奶参与世界各国专业赛事并获得提名，如在波兰获世界乳品创新奖的最佳饮品奖冠军提名等。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">9</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康鲜牛奶首次登陆中国市场，空运冷链，供应至中国部分城市的私立</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">K12</span><span style="font-size: 12px; font-family: 华文中宋;">学校。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌推出了无乳糖版本的黑巧奶。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">3</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康纯牛奶登陆新加坡、泰国、马来西亚等市场。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">4</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康纯牛奶登陆中东市场。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">6</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康纯牛奶登陆印度尼西亚市场。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">10</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">5.0%</span><span style="font-size: 12px; font-family: 华文中宋;">全脂纯牛奶独揽新西兰国家食品奖冠军。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">12</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌联合其他品牌推出了儿童休闲零食产品。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">3</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌积极抗击疫情，供应产品慰问疫情防控一线医护人员家属。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">4</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌受邀登陆天猫国际进口超市。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">5</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌受邀成立渥康天猫旗舰店。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">5</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康奶粉登陆中国市场。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">10</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌受邀成立渥康天猫海外旗舰店。</span></p>
<p style=";font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">10</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康有机奶完成总时长</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">24</span><span style="font-size: 12px; font-family: 华文中宋;">个月的有机审查，从有机农场到有机工厂再到有机产品的完整有机链认证，成功获得全球四大权威机构的有机认证（</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">AsureQuality</span><span style="font-size: 12px; font-family: 华文中宋;">有机认证、</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">BioGro</span><span style="font-size: 12px; font-family: 华文中宋;">有机认证、中检集团有机认证、中绿华夏有机认证），获得</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">30</span><span style="font-size: 12px; font-family: 华文中宋;">个国家的有机准入资格（中国、新西兰、美国、</span><span style="font-size: 12px; font-family: 华文中宋; background: white;">奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克共和国、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典）。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">10</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌受邀成为</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">IFOAM</span><span style="font-size: 12px; font-family: 华文中宋;">国际有机农业运动联盟会员，全面接受</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">IFOAM</span><span style="font-size: 12px; font-family: 华文中宋;">的国际有机管理体系监管。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2020</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">10</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康品牌受邀成为世界无乳糖组织会员。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2021</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">6</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">A2</span><span style="font-size: 12px; font-family: 华文中宋;">有机奶登陆中国市场。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2021</span><span style="font-size: 12px; font-family: 华文中宋;">年</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">6</span><span style="font-size: 12px; font-family: 华文中宋;">月，</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">WDOM</span><span style="font-size: 12px; font-family: 华文中宋;">渥康豆诺豆娜乳豆、渥康无乳糖奶分别获得</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2021</span><span style="font-size: 12px; font-family: 华文中宋;">年世界乳品创新奖的&ldquo;最佳乳制品零食奖&rdquo;冠军、&ldquo;最佳吸收乳制品奖&rdquo;冠军；渥康</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">A2</span><span style="font-size: 12px; font-family: 华文中宋;">有机奶系列亦入围了</span><span style="font-size: 12px; font-family: 华文中宋;">&ldquo;</span><span style="font-size: 12px; font-family: 华文中宋;">最佳乳饮品奖&rdquo;金奖的评选。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">&nbsp;</span></p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">质量认证：通过</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">MPI</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">新西兰初级产业部质量认证</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">新西兰有机认证：</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif; background: white;">AsureQuality</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋; background: white;">有机认证、</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif; background: white;">Biogro</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋; background: white;">有机认证</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋; background: white;">中国有机认证：</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">中国检验认证集团有机认证、<span style="background: white;">北京</span>中绿华夏有机食品认证中心有机认证</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">国际有机认证：</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">IFOAM</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">国际有机农业联盟，渥康</span><sup><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">&reg;</span></sup><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">品牌的有机从属联盟，渥康</span><sup><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">&reg;</span></sup><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">品牌获得了</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">IFOAM</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">的注册会员资格，并接受</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">IFOAM</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">的国际有机管理体系监管。渥康</span><sup><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">&reg;</span></sup><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">品牌是目前</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">IFOAM</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">国际会员体系中，唯一在列的来自新西兰的有机产品品牌。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">FODMAP* Friendly</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">认证：渥康无乳糖系列产品获得了国际</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">FODMAP* Friendly</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">认证</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal; line-height: 21px; line-height: 21px;"><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">Low FODMAP</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">认证：澳大利亚莫纳什大学</span><span style="font-size: 12px; line-height: 18px; font-family: 'Times New Roman', serif;">Low FODMAP</span><span style="font-size: 12px; line-height: 18px; font-family: 华文中宋;">认证</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 15px; font-family: 华文中宋;">产品介绍</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">纯奶系列</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 'Times New Roman', serif;">
<img style="width: 100px; height: 100px;" src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/1%20C.jpg" alt="" width="100" height="100" border="0" vspace="0" />&nbsp;
<img style="width: 100px; height: 100px;" src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/2%20C.jpg" alt="" width="100" height="100" border="0" vspace="0" />
<img style="width: 100px; height: 100px;" src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/3%20C.jpg" alt="" width="100" height="100" border="0" vspace="0" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/4%20C.jpg" width="100" height="100" />
</span></strong></p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 'Times New Roman', serif;">
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/5%20C.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/6%20C.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/zhnE.jpg" width="100" height="100" />
</span></strong></p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">功能系列</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/7%20C.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/8%20C.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/9%20C.jpg" width="100" height="100" />
</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;"></span></strong></p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">有机纯奶系列</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;">
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/10%20C.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/11%20C.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/12%20C.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/13%20C.jpg" width="100" height="100" />
</p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">奶粉系列</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;">
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/1rfE.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/2rfE.jpg" width="100" height="100" />
</span></strong></p>

<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">休食系列</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;">
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/1ywndC.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/2qklndC.jpg" width="100" height="100" />
<img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/3cndC.jpg" width="100" height="100" />
</p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 'Times New Roman', serif;">&nbsp;</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 13px; font-family: 华文中宋;">极速系列</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/417.png" width="100" height="100" /><img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/418.png" width="100" height="100" /><img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/419.png" width="100" height="100" /><img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/420.png" width="100" height="100" /></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 12px; font-family: 'Times New Roman', serif; color: #ffc000;">&nbsp;</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 15px; font-family: 华文中宋;">经营状况</span></strong></p>
<table style="width: 415px;" width="553" cellspacing="0" cellpadding="0">
<tbody>
<tr class="firstRow" style="height: 52px;">
<td style="border-width: 1px; border-color: black; padding: 0px 7px;" nowrap="nowrap" width="121" height="52">
<p style=";text-align: center; font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 华文中宋;">新西兰</span></p>
</td>
<td style="border-top-width: 1px; border-top-color: black; border-right-width: 1px; border-right-color: black; border-bottom-width: 1px; border-bottom-color: black; border-left: none; padding: 0px 7px;" nowrap="nowrap" width="432" height="52">
<p style=";font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">Countdown</span><span style="font-family: 微软雅黑, sans-serif;"><span style="font-size: 12px; font-family: 华文中宋;">、</span></span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">New World</span><span style="font-family: 微软雅黑, sans-serif;"><span style="font-size: 12px; font-family: 华文中宋;">、</span></span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">PAKn&rsquo;SAVE</span><span style="font-family: 微软雅黑, sans-serif;"><span style="font-size: 12px; font-family: 华文中宋;">等</span></span></p>
</td>
</tr>
<tr style="height: 62px;">
<td style="border-right-width: 1px; border-right-color: black; border-bottom-width: 1px; border-bottom-color: black; border-left-width: 1px; border-left-color: black; border-top: none; padding: 0px 7px;" nowrap="nowrap" height="62">
<p style=";text-align: center; font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 华文中宋;">中国线上</span></p>
</td>
<td style="border-top: none; border-left: none; border-bottom-width: 1px; border-bottom-color: black; border-right-width: 1px; border-right-color: black; padding: 0px 7px;" width="432" height="62">
<p style=";font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 华文中宋;">天猫旗舰店、天猫国际旗舰店、天猫国际超市、京东旗舰店、网易考拉旗舰店、各大银行类线上商城</span> <span style="font-size: 12px; font-family: 华文中宋;">、综合类线上购物商城</span> <span style="font-size: 12px; font-family: 华文中宋;">、母婴类线上购物商城</span></p>
</td>
</tr>
<tr style="height: 82px;">
<td style="border-right-width: 1px; border-right-color: black; border-bottom-width: 1px; border-bottom-color: black; border-left-width: 1px; border-left-color: black; border-top: none; padding: 0px 7px;" nowrap="nowrap" height="82">
<p style=";text-align: center; font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 华文中宋;">中国线下</span></p>
</td>
<td style="border-top: none; border-left: none; border-bottom-width: 1px; border-bottom-color: black; border-right-width: 1px; border-right-color: black; padding: 0px 7px;" width="432" height="82">
<p style=";font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 华文中宋;">连锁型精品超市：</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">Ole&rsquo;&amp;blt</span><span style="font-size: 12px; font-family: 华文中宋;">超市、</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">G-super </span><span style="font-size: 12px; font-family: 华文中宋;">绿地超市、</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">city&rsquo;super</span><span style="font-size: 12px; font-family: 华文中宋;">超市、</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">city shop </span><span style="font-size: 12px; font-family: 华文中宋;">城市超市、上海久光超市、</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;"> GranVida</span><span style="font-size: 12px; font-family: 华文中宋;">生活超市等</span> <span style="font-size: 12px; font-family: 华文中宋;">；</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;"><br /></span><span style="font-size: 12px; font-family: 华文中宋;">连锁型超市：永辉超市、天虹超市、友谊超市等</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;"><br /></span><span style="font-size: 12px; font-family: 华文中宋;">连锁型大卖场：沃尔玛</span></p>
</td>
</tr>
<tr style="height: 54px;">
<td style="border-right-width: 1px; border-right-color: black; border-bottom-width: 1px; border-bottom-color: black; border-left-width: 1px; border-left-color: black; border-top: none; padding: 0px 7px;" nowrap="nowrap" height="54">
<p style=";text-align: center; font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 华文中宋;">全球布局</span></p>
</td>
<td style="border-top: none; border-left: none; border-bottom-width: 1px; border-bottom-color: black; border-right-width: 1px; border-right-color: black; padding: 0px 7px;" width="432" height="54">
<p style=";font-size: 14px; font-family: Calibri, sans-serif; vertical-align: middle;"><span style="font-size: 12px; font-family: 华文中宋;">中国、新西兰、澳大利亚、泰国、新加坡、马来西亚、</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;"><br /></span><span style="font-size: 12px; font-family: 华文中宋;">印度尼西亚、迪拜、巴林、卡塔尔、阿布达比</span></p>
</td>
</tr>
</tbody>
</table>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 15px; font-family: 'Times New Roman', serif;">&nbsp;</span></strong></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><strong><span style="font-size: 15px; font-family: 华文中宋;">渥康荣誉</span></strong><span style="font-size: 12px; font-family: 'Times New Roman', serif;">&nbsp;</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><img style="width: 806px; height: 352px;" src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/421.png" alt="" width="806" height="352" border="0" vspace="0" /></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2021</span><span style="font-size: 12px; font-family: 华文中宋;">年：世界乳品创新奖（英国）</span><span style="font-size: 12px; font-family: 华文中宋;">的最佳吸收乳制品奖、最佳乳制品零食，金奖。入围</span><span style="font-size: 12px; font-family: 华文中宋;">最佳乳饮品奖，金奖评选。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2019</span><span style="font-size: 12px; font-family: 华文中宋;">年：新西兰国家食品奖（新西兰奥克兰），最佳无酒精饮品，金奖。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年：世界乳品创新奖（波兰华沙），最佳乳品奖与最佳无添加饮品奖，双项提名。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年：亚洲食品创新奖（新加坡新加坡市），最佳儿童食品奖，最佳无添加食品奖，最佳包装设计奖，三项提名。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年：国际饮料创新奖（日本东京），最佳品牌奖，最佳饮品概念奖，最佳无添加饮品奖，三项提名。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年：世界饮品创新奖（德国纽伦堡），最佳乳品奖，最佳儿童饮品奖，最佳包装设计奖，三项提名。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年：新西兰国际食品奖（新西兰奥克兰），最佳无酒精饮品提名。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年：</span><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2018</span><span style="font-size: 12px; font-family: 华文中宋;">年度新西兰高端餐厅专用牛奶，新西兰权威餐饮杂志报道。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">2017</span><span style="font-size: 12px; font-family: 华文中宋;">年：世界饮品创新奖（德国慕尼黑），最佳品牌奖，最佳商业奖双项提名；最佳儿童饮品奖，金奖。</span></p>
<p style=";text-align: justify; font-size: 14px; font-family: Calibri, sans-serif; white-space: normal;"><span style="font-size: 12px; font-family: 'Times New Roman', serif;">&nbsp;</span></p>
`
