<template>
    <div id="information">
        <div class="container-fluid">
            <div class="row cont-top">
                <div class="col-md-6 col-sm-6 col-xs-12 offset-md-3" v-html="htmlContent"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { paper1 } from "../assets/htmlContent/paper/paper_1.js";
import { paper2 } from "../assets/htmlContent/paper/paper_2.js";
import { paper3 } from "../assets/htmlContent/paper/paper_3.js";
export default {
    data() {
        return {
            htmlContent: "",
        };
    },
    mounted() {
        let id = this.$route.query.id;
        if (id == "1") {
            this.htmlContent = paper1;
        } else if (id == "2") {
            this.htmlContent = paper2;
        } else if (id == "3") {
            this.htmlContent = paper3;
        }
        let content = this.htmlContent;
        const regex = new RegExp("<img", "gi");
        const table = new RegExp("<table", "gi");

        content = content.replace(
            regex,
            `<img style="max-width: 100%; height: auto"`,
        );
         content = content.replace(
            table,
            `<table style="max-width: 100%; height: auto"`,
        );
        this.htmlContent = content
        
    },
};
</script>

<style scoped>
.cont-top {
        text-align: left;
    }
@media (min-width: 975px) {
    .cont-top {
        margin-top: 50px;
    }
}
@media (max-width: 991px) {
    .cont-top {
        margin-top: 20px;
    }
}
</style>