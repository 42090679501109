export {json as paper1}
const json = `
<h2 style="text-align: center;">
    <strong>渥康品牌方参与中国市场营销 </strong>
</h2>
<h2 style="text-align: center;">
    <strong>加码全球化战略布局</strong>
</h2>
<p>
    <strong>&nbsp;</strong>
</p>
<p style=";">
    利用品牌营销来提升品牌在消费市场的知名度和美誉度，使品牌文化与品牌价值触达消费者心理，是品牌战略的必经之路。新西兰高端乳品渥康近期在中国深圳等一线城市的核心户外及地铁沿线重磅上线其品牌广告尤为引人关注，据了解，这是渥康新西兰总部首次参与的在华市场广告投放，这不仅表明了渥康品牌对中国消费市场的高度重视，也预示着新西兰的另一乳业巨头开始参与到中国乳制品行业的竞争。
</p>
<p>
    &nbsp
</p>
<p style=";">
    <strong>卓越产品力加持 厚积薄发布局全球重点市场</strong>
</p>
<p style=";">
    渥康自1989年在新西兰创立，经过三十余载发展的沉淀与积累，如今已经成为集牧场、工厂和品牌为一体的全供应链品牌。渥取意丰盛，康取意富足，渥康寓意盛世繁华。渥康利用前沿科技，将初级乳制品改良加工，从而创造未来乳品以帮助人类达成均衡营养摄入，渥康为高端消费者提供安全且优质的乳制品，传递绿色、健康、营养和品质的生活理念。
</p>
<p style=";">
    渥康立足新西兰，面向全球发展。渥康从早期的新西兰本土市场到现如今的品牌国际化，期间完成了产品品类扩充和品牌自我迭代升级，明确指向高端消费人群的高端品牌定位。渥康涵盖低温乳品、常温乳品、液态乳品、固态乳品、消费乳品和原料乳品等多元化产品组合，其中，渥康纯牛奶是新西兰第一品牌的高端瓶装纯牛奶，并以此奠定了渥康的品牌形象与价值。经过三十余载的纵向耕耘与横向拓展，渥康在市场终端上已有不俗表现，它拥有全球稀有的有机A2、有机和A2奶源；获「新西兰国家食品奖」冠军和「世界饮品创新奖」冠军等权威殊荣。渥康成功获得AsureQuality有机认证、BioGro有机认证、中检集团有机认证、中绿华夏有机认证全球四大权威机构的有机认证，获得30个国家的有机准入资格，为渥康布局品牌国际化发展夯实坚实基础。
</p>
<p style=";">
    近年来，渥康品牌运筹帷幄，聚焦产品力与品牌力持续深耕，步步为营地实现全球化战略布局，加速扩大世界版图和提升品牌国际影响力。至今，渥康深深扎根于全球消费市场，以高含金量的『新西兰制造』向全世界展现品质高端乳品的品牌价值，凭借产品核心竞争优势已在全球20多个国家的高端乳品领域均占据着市场份的一席之地，蕴藏着无限的市场爆发潜力。
</p>
<p>
    &nbsp;
</p>
<p style=";">
    <strong>高端乳品需求强劲 创新营销深耕中国市场</strong>
</p>
<p style=";">
    得益于天然牧场、世界依靠的生产体系，严苛的食品安全监管体系三大优势，使新西兰奶在全球乳品行业均拥有举足轻重的发言权。随着消费升级和全民营养健康意识觉醒，中国消费者对安全优质高端乳品需求呈明显持续上升趋势。据京东大数据显示，2020年线上乳品消费的趋势呈现高端化、健康化和功能细分化等特征。2020年有机奶成交额同比增速是牛奶整体增速的2.5倍，而高品质的A2蛋白牛奶年成交额同比增长达800%。
</p>
<p style=";">
    基于中国消费者对新西兰优质、安全、纯净的高端奶制品有着强烈的诉求与信任，且这部分人群拥有强大的消费能力，精研洞悉消费需求的渥康借此契机加码发力中国消费市场亦是无可厚非。自2018年渥康正式登陆中国市场以来，先后开设京东旗舰店、天猫旗舰店、天猫海外旗舰店等主流线上品牌旗舰店，另外，在高端连锁超市、进口超市、高端母婴连锁店和全球型连锁便利店等线下实体店均有销售渠道布局。渥康纯牛奶的营养数值均处于全球消费市场产品的领先水平;而据2021年5月在天猫 PC 端发起的市场调查数据结果显示：渥康全脂牛乳粉的每 100g 奶粉中的蛋白质含量 26.7mg 在高于同类产品的平均值，<strong>并超出2.5mg，</strong>渥康乳粉的高蛋白完全契合疫情和后疫情时期，消费者对营养补充的需求，强身健体，强化自身抵抗力。
</p>
<p style="text-align: center">
    <img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/1.png"/>
</p>
<p style=";">
    渥康多维度的品牌展示并以深圳为中心辐射珠三角城市乃至全国，足以证明渥康品牌对中国市场的重视，塑造高端品牌形象与魅力，提升品牌辨识度与影响力。据了解，以往的渥康品牌均由国内代理商代理运营，本次渥康品牌瞄准中国市场亲自操刀营销运营，在横贯深圳核心都市圈的1号线地铁全线单边广告覆盖，1、2、5、7、9、11号线地铁梯版全线覆盖，会展中心、车公庙、高新园、前海湾、后海等数十个S级站点主题站厅和核心位置高密度投放广告。地铁媒介是城市文化传播的新载体，具备曝光率高、覆盖人群量大特点，对品宣和营销效果极其出色，渥康以此实现多元化、场景化、沉浸式营销模式转化和升级，强化品牌渗透效应，让消费者对品牌产生深层次的认知和文化认同，打造卓越的品牌IP，促进双方情感链接，最终提高转化率。
</p>
<p style="text-align: center">
    <img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/2.png"/>
</p>
<p style="text-align: center">
    <img src="https://wdom-home.oss-cn-qingdao.aliyuncs.com/image/3.png"/>
</p>
<p style=";">
    奋发进取，未雨绸缪。有分析报告指出，2021年，注重产品品质和消费体验的高质量消费人群将进一步成为主流消费群体，乳品市场将持续走向品质化、品牌化和功能细分化的道路。渥康将一如既往秉持品牌初心，持续打造高端乳品生态圈价值链，保障营养科学产品体系，释放品牌活力，提速中国消费市场乃至全球市场的占领进程，不遗余力守护全球消费者的健康品质生活。
</p>
<p>
    &nbsp;
</p>
<p>
    <br/>
</p>
`